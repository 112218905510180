
  import { defineComponent, ref, watchEffect } from 'vue'
  import MyUploadBtn from '../../../components/MyUpload/index.vue'
  import { BookFilled, DeleteFilled } from '@ant-design/icons-vue'
  export default defineComponent({
    props: {
      datajson: String,
    },
    components: { MyUploadBtn, BookFilled, DeleteFilled },
    setup(props, ctx) {
      const items: any = ref([])
      const calculation = ref(0)
      watchEffect(() => {
        const data = props.datajson as any
        if (data) {
          items.value = JSON.parse(data)
          Calculation()
        }
      })
      const Calculation = () => {
        let number = 0
        items.value.forEach((res: any) => {
          res.content.forEach((json: any) => {
            number += parseFloat(json.total)
          })
        })
        calculation.value = number
      }
      const uploadImgFun = (row: any) => {
        if (!items.value[row.index].content[row.idxs].info) {
          items.value[row.index].content[row.idxs].info = []
        }
        let items_arr: any = items.value[row.index]
        items_arr.content[row.idxs].info.push({
          url: row.url,
          name: '成果文件',
        })
        ctx.emit('onchange', JSON.stringify(items.value))
        console.log(items_arr, 'kskks')
      }
      return {
        items,
        calculation,
        uploadImgFun,
      }
    },
  })
