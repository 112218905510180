
  import { defineComponent, watchEffect, ref } from 'vue'
  export default defineComponent({
    props: {
      list: String,
    },
    setup(props) {
      const items = ref([])
      watchEffect(() => {
        if (props.list) {
          items.value = JSON.parse(props.list as any)
        }
      })
      return {
        items,
      }
    },
  })
