
  import { defineComponent, ref, watchEffect } from 'vue'
  import { FolderOutlined, FolderOpenOutlined } from '@ant-design/icons-vue'
  export default defineComponent({
    components: { FolderOutlined, FolderOpenOutlined },
    props: {
      datajson: String,
    },
    setup(props) {
      const items = ref([])
      watchEffect(() => {
        const data = props.datajson as any
        items.value = data ? JSON.parse(data) : []
      })
      const downloadFun = (url: string, name: string) => {
        console.log(url.split('.'), 'sllkls')
        const downloadInfo = url.split('/')
        const downloadInfo2 = url.split('.')
        const names = name
          ? name + '.' + downloadInfo2[downloadInfo2.length - 1]
          : downloadInfo[downloadInfo.length - 1]
        const elt = document.createElement('a')
        elt.setAttribute('href', url)
        elt.setAttribute('download', names)
        elt.setAttribute('target', '_blank')
        elt.style.display = 'none'
        document.body.appendChild(elt)
        elt.click()
      }
      return {
        items,
        downloadFun,
      }
    },
  })
